import React from "react";
import "./Mission.css";

import { Grid, Typography, Box } from "@mui/material";
import MissionItem from "./MissionItem";

class Mission extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Box>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Our Mission - <span style={{ color: "#da6b63" }}>S</span>
            <span style={{ color: "rgba(0,212,255,1)" }}>E</span>
            <span style={{ color: "rgba(34,195,141,1)" }}>E</span>
            <span style={{ color: "rgba(253,173,45,1)" }}>D</span>
          </Typography>

          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            To build a safe community through support, education, empowerment, and development for Asian youth.
          </Typography>

          <Grid container sx={{ p: 5 }}>
            <MissionItem
              type={"normal"}
              emoji={"🤗"}
              title={"Support"}
              description={
                "AYA is a safe and nonjudgmental environment where everyone is welcome."
              }
              color={"linear-gradient(to right, #da6b63 0%, #f68d72 100%);"}
            />
            <MissionItem
              type={"normal"}
              emoji={"🎓"}
              title={"Education"}
              description={
                "Workshops teach about topics relevant to our modern society, such as youth mental health, breaking the Asian stereotype, and leadership in uncertain times."
              }
              color={"linear-gradient(to right, rgba(131,123,255,1) 0%, rgba(0,212,255,1) 100%);"}
            />
            <MissionItem
              type={"normal"}
              emoji={"✊"}
              title={"Empowerment"}
              description={
                "We help provide the tools to succeed, equipping them to make a lasting impact on society."
              }
              color={"linear-gradient(to right, rgba(34,195,141,1) 0%, rgba(45,253,58,1) 100%);"}
            />
            <MissionItem
              type={"normal"}
              emoji={"🌳"}
              title={"Development"}
              description={
                "We guide them on their journey to become the whole person and best version of themselves, physically, emotionally, and mentally."
              }
              color={"linear-gradient(to right, rgba(253,173,45,1) 0%, rgba(255,230,0,1) 100%);"}
            />
          </Grid>
        </Box>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Box>
          <Typography
            variant="h4"
            sx={{ pt: 5, textAlign: "center" }}
            gutterBottom
          >
            Our Mission - <span style={{ color: "#da6b63" }}>S</span>
            <span style={{ color: "rgba(0,212,255,1)" }}>E</span>
            <span style={{ color: "rgba(34,195,141,1)" }}>E</span>
            <span style={{ color: "rgba(253,173,45,1)" }}>D</span>
          </Typography>
          <Grid container sx={{ p: 2 }}>
          <MissionItem
              type={"mobile"}
              emoji={"🤗"}
              title={"Support"}
              description={
                "AYA is a safe and nonjudgmental environment where everyone is welcome."
              }
              color={"linear-gradient(to right, #da6b63 0%, #f68d72 100%);"}
            />
            <MissionItem
              type={"mobile"}
              emoji={"🎓"}
              title={"Education"}
              description={
                "Workshops teach about topics relevant to our modern society, such as youth mental health, breaking the Asian stereotype, and leadership in uncertain times."
              }
              color={"linear-gradient(to right, rgba(131,123,255,1) 0%, rgba(0,212,255,1) 100%);"}
            />
            <MissionItem
              type={"mobile"}
              emoji={"✊"}
              title={"Empowerment"}
              description={
                "We help provide the tools to succeed, equipping them to make a lasting impact on society."
              }
              color={"linear-gradient(to right, rgba(34,195,141,1) 0%, rgba(45,253,58,1) 100%);"}
            />
            <MissionItem
              type={"mobile"}
              emoji={"🌳"}
              title={"Development"}
              description={
                "We guide them on their journey to become the whole person and best version of themselves, physically, emotionally, and mentally."
              }
              color={"linear-gradient(to right, rgba(253,173,45,1) 0%, rgba(255,230,0,1) 100%);"}
            />
          </Grid>
        </Box>
      );
    }
  }
}

export default Mission;
