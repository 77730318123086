import React from "react";
import "./About.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { ImageList } from "@mui/material";
import { ImageListItem } from "@mui/material";

import cooking1 from "../resources/cooking1.jpg";
import cooking2 from "../resources/cooking2.jpg";
import cooking3 from "../resources/cooking3.jpg";
import cooking4 from "../resources/cooking4.jpg";
import cooking5 from "../resources/cooking5.jpg";
import cooking6 from "../resources/cooking6.jpg";
import cooking7 from "../resources/cooking7.jpg";
import cooking8 from "../resources/cooking8.jpg";
import cooking9 from "../resources/cooking9.jpg";
import cooking10 from "../resources/cooking10.jpg";
import cooking11 from "../resources/cooking11.jpg";
import cooking12 from "../resources/cooking12.jpg";
import cooking13 from "../resources/cooking13.jpg";
import cooking14 from "../resources/cooking14.jpg";
import cooking16 from "../resources/cooking16.jpg";
import cooking17 from "../resources/cooking17.jpg";
import cooking18 from "../resources/cooking18.jpg";
import cooking19 from "../resources/cooking19.jpg";
import cooking20 from "../resources/cooking20.jpg";
import cooking21 from "../resources/cooking21.jpg";
import cooking22 from "../resources/cooking22.jpg";
import cooking23 from "../resources/cooking23.jpg";
import cooking24 from "../resources/cooking24.jpg";
import cooking25 from "../resources/cooking25.jpg";

import workshop1 from "../resources/workshop1.jpg";
import workshop2 from "../resources/workshop2.jpg";
import workshop3 from "../resources/workshop3.jpg";
import workshop4 from "../resources/workshop4.jpg";
import workshop5 from "../resources/workshop5.jpg";
import workshop6 from "../resources/workshop6.jpg";
import workshop8 from "../resources/workshop8.jpg";
import workshop9 from "../resources/workshop9.jpg";
import workshop10 from "../resources/workshop10.jpg";
import workshop11 from "../resources/workshop11.jpg";

import mohit1 from "../resources/mohit1.jpg"
import mohit2 from "../resources/mohit2.jpg"
import mohit3 from "../resources/mohit3.jpg"
import mohit4 from "../resources/mohit4.jpg"
import mohit5 from "../resources/mohit5.jpg"
import mohit6 from "../resources/mohit6.jpg"

import tasteofdiversity2 from "../resources/tasteofdiversity2.jpg"
import tasteofdiversity3 from "../resources/tasteofdiversity3.jpg"
import tasteofdiversity4 from "../resources/tasteofdiversity4.jpg"
import tasteofdiversity5 from "../resources/tasteofdiversity5.jpg"
import tasteofdiversity6 from "../resources/tasteofdiversity6.jpg"
import tasteofdiversity7 from "../resources/tasteofdiversity7.jpg"
import tasteofdiversity9 from "../resources/tasteofdiversity9.jpg"
import tasteofdiversity10 from "../resources/tasteofdiversity10.jpg"
import tasteofdiversity11 from "../resources/tasteofdiversity11.jpg"
import tasteofdiversity12 from "../resources/tasteofdiversity12.jpg"
import tasteofdiversity13 from "../resources/tasteofdiversity13.jpg"
import tasteofdiversity14 from "../resources/tasteofdiversity14.jpg"
import tasteofdiversity15 from "../resources/tasteofdiversity15.jpg"
import tasteofdiversity16 from "../resources/tasteofdiversity16.jpg"
import tasteofdiversity17 from "../resources/tasteofdiversity17.jpg"
import tasteofdiversity18 from "../resources/tasteofdiversity18.jpg"
import tasteofdiversity19 from "../resources/tasteofdiversity19.jpg"
import tasteofdiversity20 from "../resources/tasteofdiversity20.jpg"
import tasteofdiversity21 from "../resources/tasteofdiversity21.jpg"
import tasteofdiversity22 from "../resources/tasteofdiversity22.jpg"
import tasteofdiversity23 from "../resources/tasteofdiversity23.jpg"
import tasteofdiversity28 from "../resources/tasteofdiversity28.jpg"
import tasteofdiversity29 from "../resources/tasteofdiversity29.jpg"
import tasteofdiversity30 from "../resources/tasteofdiversity30.jpg"

import secondworkshop2 from "../resources/secondworkshop2.jpg"
import secondworkshop3 from "../resources/secondworkshop3.jpg"
import secondworkshop4 from "../resources/secondworkshop4.jpg"
import secondworkshop5 from "../resources/secondworkshop5.jpg"
import secondworkshop7 from "../resources/secondworkshop7.jpg"
import secondworkshop8 from "../resources/secondworkshop8.jpg"
import secondworkshop9 from "../resources/secondworkshop9.jpg"
import secondworkshop10 from "../resources/secondworkshop10.jpg"
import secondworkshop12 from "../resources/secondworkshop12.jpg"
import secondworkshop13 from "../resources/secondworkshop13.jpg"

import picnic1 from "../resources/picnic1.jpg"
import picnic2 from "../resources/picnic2.jpg"
import picnic3 from "../resources/picnic3.jpg"
import picnic4 from "../resources/picnic4.jpg"
import picnic5 from "../resources/picnic5.jpg"

let picnicData = [
  picnic1, 
  picnic2,
  picnic3,
  picnic4
];

let secondWorkshopData = [
  secondworkshop8,
  secondworkshop3,
  secondworkshop4,
  secondworkshop5,
  secondworkshop7,
  secondworkshop2,
  secondworkshop9,
  secondworkshop13,
  secondworkshop12,
  secondworkshop10,
];

let tasteOfDiversityData = [
  tasteofdiversity28,
  tasteofdiversity2,
  tasteofdiversity3,
  tasteofdiversity4,
  tasteofdiversity5,
  tasteofdiversity6,
  tasteofdiversity7,
  tasteofdiversity9,
  tasteofdiversity10,
  tasteofdiversity11,
  tasteofdiversity12,
  tasteofdiversity13,
  tasteofdiversity14,
  tasteofdiversity15,
  tasteofdiversity16,
  tasteofdiversity17,
  tasteofdiversity18,
  tasteofdiversity19,
  tasteofdiversity20,
  tasteofdiversity21,
  tasteofdiversity22,
  tasteofdiversity23,
  tasteofdiversity30,
  tasteofdiversity29,
];

let mohitItemData = [
  mohit6,
  mohit3,
  mohit1,
  mohit4,
  mohit5,
  mohit2
];

let cookingItemData = [
  cooking12,
  cooking2,
  cooking3,
  cooking4,
  cooking6,
  cooking19,
  cooking7,
  cooking13,
  cooking9,
  cooking10,
  cooking8,
  cooking5,
  cooking23,
  cooking16,
  cooking14,
  cooking17,
  cooking24,
  cooking1,
  cooking20,
  cooking21,
  cooking22,
  cooking11,
  cooking18,
  cooking25,
];

let picnicItemData = [
  "https://lh3.googleusercontent.com/pw/AM-JKLWFxCeNzyfWtMb-U5wgseCb5ZZktKbVAbmG2rimpyUBUvhI-f63ikmgKET5nTTrVN3RBxCIrMGLeFAwR7hbixpjSi9O1syXNQChZYv-qDQoE_4iakc9D_JTJvFbc6nqZRGcKckRTQENJ48CaQTslr-W=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLVupP6spSoI6UTcDVK4MePOSpqTC92x9qf8C0shWU3FIubiPZFDg0HQ2Kj8sNM4cD_zGH8oflhDCrfLML74OOZATaKCxcmoP3MR1SLT-owBO9KW47fn5dk-dpyXGVSRo3ZWvHMltvMnv_jh38Xme9bh=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLUWrerNd6my2EwzwmkXhOP2mspgux63suWIrjMQWnHCje_7S3IA9TO9nOBH_Ar6RH-mlpWAiu6eyUQ9L3mCPDpHwRfFHbldEwhS37XSupwD694A3scrSqS1CKyJ_BvL6jnO9Y3YiwuOcVt9MgXaK30o=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLV9esyvHvNvDu4KgWpYIKF6OBg4lfYwDS7n4eytk5a8vVDqzcmDsnKxcgoWe2H2dR88m7hrczyBrFomBCJnBbaua5jaEccqwKK95KevuXcQGSM4d2WqNLSm9FgOUxHyS8LW4CHReQelzRgDxFHzWJOH=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLUXrlaeskkupMISnq1urHrB29c9SMORfAPwix30R2xNgZu1QvxB_VlycuJJb_p5QKD9ij7LsrL269SuNkRqgBg-omLtX4B9uZy4hs66MGLSeWaR3WluYWdRcZI124Tt_LqA_3T60W8rDkXdEL8PqrnS=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLX3CxlVxu67SLYwA94MRnCCxnQxLfKTsa82dVp8CfWvrwW_FE0bf1s2eKCfKMHUSIqmU4W9wGvEnGLLZhAQiueBIISUsRiobTkAu8nHGokLnJ4FlBsRL4iWaGQBycenpFNuZrEPtuXCYyJwL3r_H4-7=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLWK8RtTpwWTlHnZjY6cH4f10FSqlb1o1_E4k9A6N2xir9cJ3PfWTcSgnR-koMTvIFCFG8uArjSagHSWx8F8S8yBmhrl3aS_tdR3OxqORqwLaX7Ni62IYVKvWNZRQ2p5HqT07bL_EBne9nPpXaKE1txA=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLUcY_j2SljxQ0j8-UtRbMuh2jmVq7IdXgr8GbbXxLzVIOphr_XDKZKr-5cIzQbL3NUKkk_f4UavmvyJlPZy2uq93df9hqbNp9xBtYU0dYaN8z0xt_eSNRVNuARr-tVmFf8lx54J-b5RtHfYU1AoS3xT=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLXqNzGCIc-rdh-H01s2w-LP8ZfCCjJZ6RGzEXqR_hwHIv-JPcTGMCzCvCgPEi16RJxV5qCAsDlVbRiIZCwbHrC_4gpSUQhSffFh8Ic-xd-uiFU86YbzlT9TdDVk6MswaXYTOVzwhp2VB7icU-z__l-g=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLUvuVvhuiQFnIyIoasQ4sK-gUejgxYarP_wMU06H9fJqZl1TUUCFirgii-BbKcz6PkXX-fIa08NtsC69ifo8jDz80dvhErKJzH1FV0CHfN8p_El89JffNMZCpg3SUGH958M8cGm-_M9KGYaWHg061ew=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLXteYz5Ms_hf8eWVJCeXnBqXR6_5AaYo6aiEyR0reDp2jslaSEBBYKrziu_5AkfbuRdP1Cwd6lghapHuPJLmpbIrQich-ayX_LW8C7rgfBID1zs5rCtqEeZiC0CkSLBYMQl_0tTHWhF58BdSvkAsFTf=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLV4zAxoSaYjnmpLD0nwrY789ENW9qeDJtstBbbSXeRv6gNCyU25VK8hWgLpdGxfpVxgV26xOnXkni3a_0m5HuFz9Lyvvt0oVlkSFFu3burqCmmkPUIYho6fHHuOKIAquTImOgQm_kcgcT_QWsTtAKi3=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLXmibYMljPJS4clU02VY5mHCuS6QgF1dZunI98jzMAkw9lJ_1490J0OaRPCeYtIwkxtKmXOWWGb9Yj8XH5kKMV71LckfxBEOONCmurCU-3j59hdSIGHRCbKPRa5uJ9u1EbiuXagkugrjfoKzOrKQ25i=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLWcq57umc_sh5Paq97sL6XNBjq2wpnZ6flx2m5A5kgOXpRGUZERN7qbTMI3Vehc4mFUeR9G4SyuAKxMA_XbjgQmhUzUZQcQXD3a6_b_sKacIGVXmjPJ30rQeRyuZbw0ffmoXGfk8e-HRyq4TmcLGerS=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLU9ISbc-AWoZvj0UMHPVdDgfUNiBBa1xkIWsdQ7iQjO-vQMMF5-qrhcm9fy5s_62TO6QMQVDmdevIafFwbEC-9Rahx9BHJckbc8WgwZlF3Kn2T7L5av9YV8yvBch6e9orQtdkUVSGS87yAJLXnOALyW=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLUouNz-hNLogeIccPBP81SnxhAg2NqFq88x3crspiOaCDK8KgeJCi_VcFNPJXhHUjE-PSZhrA9g10alLx1m1p-hVc4ZCLLk9x2onEt4Zxo2GiojqtzAX0hJvlAmT8Wt2jJRlXoFD2HKurL3jDjIPVCu=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLWKRk7S-T4piIPYLKx9njRZtUUSHvEcP-0Gaz3jEt6LARc8GBnQtNifC9aDNbq_xHP5p6C6pqBNfGwVtMFv3LHuXyQD33hwWtynUNgSGVan7KyMod0FY-u7o7qbaNmlrvqM7mlrSagyFR9PBE3vWjjP=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLXtZ30vT_4QSnVum7XlB3aknMVVMUgl4pGSGyo33NlKHESwLghn17tNLdXZKZwMPUeCA4hUlIDtzPZ5qDR-oVwHQc2qlQIdUMh2lL_DV3_Y4kr6HezeYeYWZbd200NoJUHCSg28GYD5Ojxtmq4dKfYx=w1299-h866-no?authuser=0",
  "https://lh3.googleusercontent.com/pw/AM-JKLVlX7SGNwo9JXdY3H5k8Qs6HXpNS4FLzX1LDf6gEhjSDW8TxQycu4gxKF6iF3fi33PyS6HplSdrxEWu9ZR9rM2h2tWPVWbmxtpDYSND1OA6i8MXg7O3H-UqIfruyOWYRAPafZdCohgr4BdFBqUC1KSP=w1299-h866-no?authuser=0",
];

let workshopItemData = [
  workshop1,
  workshop2,
  workshop3,
  workshop4,
  workshop5,
  workshop6,
  workshop11,
  workshop9,
  workshop10,
  workshop8,
];

function Gallery() {
  function srcset(image, size, rows, cols) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Box
        sx={{
          backgroundColor: "grey.200",
          position: "absolute",
          top: "40px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: "100px"
        }}
      >
        <Typography variant="h3" sx={{ mt: 3, textAlign: "center" }}>
          Gallery
        </Typography>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Asian Youth Picnic at Cottell Park, 9/3/22
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={2}
          rowHeight={400}
          variant="quilted"
        >
          {picnicData.map((item, index) => (
            <ImageListItem
              key={index}
              cols={1}
              rows={1}
            >
              <img
                {...srcset(
                  item,
                  200,
                  1,
                  1
                )}
                alt={""}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Asian Youth Leadership Workshop with Guest Speaker Hamilton County Clerk of Courts Mohit Mehan at Church Venture, 8/5/22-8/6/22
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={4}
          rowHeight={200}
          variant="quilted"
        >
          {secondWorkshopData.map((item, index) => (
            <ImageListItem
              key={item}
              cols={index % 7 === 0 ? 2 : 1}
              rows={index % 7 === 0 ? 2 : 1}
            >
              <img src={item} alt={""} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Asian Youth Alliance Taste of Diversity at Dulle Park Shelter, 6/10/22
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={4}
          rowHeight={200}
          variant="quilted"
        >
          {tasteOfDiversityData.map((item, index) => (
            <ImageListItem
              key={item}
              cols={index % 7 === 0 ? 2 : 1}
              rows={index % 7 === 0 ? 2 : 1}
            >
              <img src={item} alt={""} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Asian Youth Get Together with Guest Speaker Mohit Mehan at Blue Ash Recreational Center, 4/15/22
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={3}
          rowHeight={200}
          variant="quilted"
        >
          {mohitItemData.map((item, index) => (
            <ImageListItem
              key={index}
              cols={1}
              rows={1}
            >
              <img
                {...srcset(
                  item,
                  200,
                  1,
                  1
                )}
                alt={""}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Cooking Lessons at Out of Thyme Kitchen Studio, 1/14/22
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={4}
          rowHeight={200}
          variant="quilted"
        >
          {cookingItemData.map((item, index) => (
            <ImageListItem
              key={item}
              cols={index % 7 === 0 ? 2 : 1}
              rows={index % 7 === 0 ? 2 : 1}
            >
              <img src={item} alt={""} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Youth Picnic at Swaim Park, 8/27/21
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0 }}
          cols={4}
          rowHeight={200}
          variant="quilted"
        >
          {picnicItemData.map((item, index) => (
            <ImageListItem
              key={index}
              cols={index % 7 === 0 ? 2 : 1}
              rows={index % 7 === 0 ? 2 : 1}
            >
              <img
                {...srcset(
                  item,
                  200,
                  index % 7 === 0 ? 2 : 1,
                  index % 7 === 0 ? 2 : 1
                )}
                alt={""}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Typography
          variant="h4"
          sx={{
            color: "#3d61ff",
            m: 3,
            textAlign: "left",
            width: "calc(100% - 20vw)",
          }}
        >
          Asian Youth Workshop with Guest Speaker Cincinnati Mayor Aftab Pureval at Mason Municipal Center, 8/6/21-8/7/21
        </Typography>

        <ImageList
          sx={{ width: "80vw", position: "static", zIndex: 0}}
          cols={4}
          rowHeight={200}
          variant="quilted"
        >
          {workshopItemData.map((item, index) => (
            <ImageListItem
              key={index}
              cols={index % 7 === 0 ? 2 : 1}
              rows={index % 7 === 0 ? 2 : 1}
            >
              <img
                {...srcset(
                  item,
                  200,
                  index % 7 === 0 ? 2 : 1,
                  index % 7 === 0 ? 2 : 1
                )}
                alt={""}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    );
  } else {
    return (
      <h1>
        <br />
        Please view the gallery on a tablet or larger device.
      </h1>
    );
  }
}

export default Gallery;
