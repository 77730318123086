import React from "react";
import "./RecentEventCard.css";

import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material"

class RecentEventCard extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Grid
          item
          xs={4}
          md={4}
          sx={{
            position: "relative",
            p: { xs: 2, md: 2 },
          }}
        >
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={this.props.image}
              alt="Card Media"
            />
            <CardContent>
              <Typography variant="h4">{this.props.title}</Typography>
              <Typography variant="p" paragraph>
                {this.props.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            position: "relative",
            p: { xs: 2, md: 2 },
          }}
        >
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={this.props.image}
              alt="Card Media"
            />
            <CardContent>
              <Typography variant="h4">{this.props.title}</Typography>
              <Typography variant="p" paragraph>
                {this.props.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }
  }
}

export default RecentEventCard;
