import React from "react";
import "./Footer.css";

// Elements
import { Grid, Typography, Link, Paper, Box } from "@mui/material";

// Photos
import workshop6 from "../resources/workshop6.jpg";

class Footer extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Paper
          sx={{
            position: "relative",
            borderRadius: 0,
            backgroundColor: "grey.800",
            color: "#fff",
            opacity: 0.9,
            mb: 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minHeight: "50vh",
          }}
        >
          {<img style={{ display: "none" }} src={workshop6} alt="background" />}
          <Box
            sx={{
              backgroundColor: "rgba(0,0,0,.3)",
              minHeight: "40vh",
              pl: 10,
              pr: 10,
              pt: 2,
              pb: 2,
            }}
          >
            <Box sx={{ minHeight: "30vh" }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">Our Mission</Typography>
                  <Typography variant="p">
                    The Asian Youth Alliance aims to serve the Asian youth,
                    planting SEEDs within the community to help them thrive in
                    life.
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: "right" }} xs={8}>
                  <Typography
                    sx={{
                      color: "white",
                      p: 0,
                    }}
                    variant="h5"
                  >
                    Sponsored by the
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      p: 0,
                    }}
                    variant="h4"
                  >
                    <a
                      style={{ color: "white" }}
                      href="https://asiancommunityalliance.org/"
                    >
                      <img
                        alt="footer"
                        className="footer-image"
                        src="https://asiancommunityalliance.org/wp-content/uploads/2017/07/fav-1.png"
                      />{" "}
                      Asian Community Alliance
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ minHeight: "10vh" }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Grid item>
                  <Typography>
                    Copyright 2022 - All Rights Reserved
                    <br />
                    Made by Kyle Macasilli-Tan
                  </Typography>
                </Grid>
                <Grid item>
                  <Link to="/" className="footer-links">
                    Home
                  </Link>
                  <Link to="/about" className="footer-links">
                    About
                  </Link>
                  <Link to="/gallery" className="footer-links">
                    Gallery
                  </Link>
                  <a
                    href="http://eepurl.com/hBkBQv"
                    className="footer-links"
                    target="blank"
                  >
                    Sign Up
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      );
    } else if (this.props.type === "mobile") {
      return (
        <div className="footer">
          <h4>
            <a className="footer-link" href="#home">
              Return to Top
            </a>
          </h4>
        </div>
      );
    }
  }
}

export default Footer;
