import React from "react";
import "./UpcomingEventCard.css";

import { Card, CardMedia, CardContent, Typography } from "@mui/material"

class UpcomingEventCard extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Card sx={{ display: "flex", mt: 5 }}>
          <CardMedia
            component="img"
            sx={{ width: this.props.imageWidth, height: this.props.imageHeight }}
            image={this.props.image}
            alt="Card Media"
          />
          <CardContent>
            <Typography variant="h4">{this.props.title}</Typography>
            <Typography variant="p" paragraph>
              {this.props.description}
            </Typography>
          </CardContent>
        </Card>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Card sx={{ mt: 5 }}>
          <CardMedia
            component="img"
            sx={{ width: this.props.imageWidth, height: this.props.imageHeight }}
            image={this.props.image}
            alt="Card Media"
          />
          <CardContent>
            <Typography variant="h4">{this.props.title}</Typography>
            <Typography variant="p" paragraph>
              {this.props.description}
            </Typography>
          </CardContent>
        </Card>
      );
    }
  }
}

export default UpcomingEventCard;
