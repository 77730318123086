import React from "react";
import "./TeamProfile.css";

import { Grid, Typography } from "@mui/material"

class TeamProfile extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Grid
          item
          xs={4}
          sx={{
            position: "relative",
            p: { xs: 2, md: 2 },
            textAlign: "center",
          }}
        >
          <img className="team-image" alt={"Team Profile"} src={this.props.image} />
          <Typography variant="h4">{this.props.name}</Typography>
          <Typography paragraph>{this.props.school}</Typography>
          <Typography paragraph>{this.props.position}</Typography>
          <Typography paragraph>{this.props.year}</Typography>
        </Grid>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Grid
          item
          xs={12}
          sx={{
            position: "relative",
            p: { xs: 2, md: 2 },
            textAlign: "center",
          }}
        >
          <img className="team-image" alt={"Team Profile"} src={this.props.image} />
          <Typography variant="h4">{this.props.name}</Typography>
          <Typography paragraph>{this.props.school}</Typography>
          <Typography paragraph>{this.props.position}</Typography>
          <Typography paragraph>{this.props.year}</Typography>
        </Grid>
      );
    }
  }
}

export default TeamProfile;
