import React from "react";
import "./MissionItem.css";

import { Grid, Box, Typography } from "@mui/material";

class MissionItem extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Grid
          item
          lg={6}
          xl={3}
          sx={{
            position: "relative",
            p: { xs: 2 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background:
                this.props.color,
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              p: 2,
            }}
          >
            <h1 className="emoji-big">{this.props.emoji}</h1>
            <Box sx={{ color: "white" }}>
              <Typography variant="h4">{this.props.title}</Typography>
              <Typography paragraph>{this.props.description}</Typography>
            </Box>
          </Box>
        </Grid>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Grid
          item
          xs={12}
          sx={{
            position: "relative",
            p: { xs: 2 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background:
                this.props.color,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
          >
            <h1 className="emoji-big">{this.props.emoji}</h1>
            <Box sx={{ color: "white" }}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                {this.props.title}
              </Typography>
              <Typography paragraph>{this.props.description}</Typography>
            </Box>
          </Box>
        </Grid>
      );
    }
  }
}

export default MissionItem;
