import React from "react";
import "./RecentEvents.css";

// Elements
import RecentEventCard from "./RecentEventCard";
import { Box, Typography, Grid } from "@mui/material";

// Photos
import secondworkshop3 from "../resources/secondworkshop3.jpg";
import tasteofdiversity22 from "../resources/tasteofdiversity22.jpg";
import cooking9 from "../resources/cooking14.jpg";
import workshop3 from "../resources/workshop3.jpg";
import mohit5 from "../resources/mohit5.jpg"



class RecentEvents extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Box sx={{ backgroundColor: "grey.200" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Recent Events
          </Typography>
          <Grid container sx={{ p: 2 }}>
            <RecentEventCard
              type={"normal"}
              image={secondworkshop3}
              title={"Asian Youth Leadership Workshop"}
              description={
                "Pavan Parikh, Hamilton County Clerk of Courts, spoke at AYA's second annual Youth Leadership Workshop, with workshops on managing time and interviewing skills."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={tasteofdiversity22}
              title={"Taste of Diversity"}
              description={
                "AYA members shared their unique cuisine, providing all members with the opportunity to try foods from various Asian cultures."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={mohit5}
              title={"Asian Youth Get Together"}
              description={
                "Mohit Mehan performed stand-up comedy at a get-together with boba tea and pizza, connecting Asian youth in the community."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={cooking9}
              title={"Cooking Lessons"}
              description={
                "AYA hosted knife and cooking lessons to teach Asian youth important life skills."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={
                "https://lh3.googleusercontent.com/pw/AM-JKLU9ISbc-AWoZvj0UMHPVdDgfUNiBBa1xkIWsdQ7iQjO-vQMMF5-qrhcm9fy5s_62TO6QMQVDmdevIafFwbEC-9Rahx9BHJckbc8WgwZlF3Kn2T7L5av9YV8yvBch6e9orQtdkUVSGS87yAJLXnOALyW=w1299-h866-no?authuser=0"
              }
              title={"Picnic"}
              description={
                "A picnic was held at Swaim Park to encourage inclusiveness and allow Asian youth in the community to get to know each other and have fun."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={workshop3}
              title={"Workshop"}
              description={
                "The youth workshop covered relevant topics like the Asian stereotype, mental health, careers, resume writing, and interview skills, with special guest speaker Aftab Pureval, mayor of Cincinnati."
              }
            />
          </Grid>
        </Box>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Box sx={{ backgroundColor: "grey.200" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Recent Events
          </Typography>

          <Grid container sx={{ p: 2 }}>
          <RecentEventCard
              type={"normal"}
              image={mohit5}
              title={"Asian Youth Leadership Workshop"}
              description={
                "Pavan Parikh, Hamilton County Clerk of Courts, spoke at AYA's second annual Youth Leadership Workshop, with workshops on managing time and interviewing skills."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={tasteofdiversity22}
              title={"Taste of Diversity"}
              description={
                "AYA members shared their unique cuisine with the opportunity to try foods from various Asian cultures."
              }
            />
            <RecentEventCard
              type={"normal"}
              image={mohit5}
              title={"Asian Youth Get Together"}
              description={
                "Mohit Mehan performed stand-up comedy at a get-together with boba tea and pizza, connecting Asian youth in the community."
              }
            />
            <RecentEventCard
              type={"mobile"}
              image={cooking9}
              title={"Cooking Lessons"}
              description={
                "AYA hosted knife and cooking lessons to teach Asian youth important life skills."
              }
            />
            <RecentEventCard
              type={"mobile"}
              image={
                "https://lh3.googleusercontent.com/pw/AM-JKLU9ISbc-AWoZvj0UMHPVdDgfUNiBBa1xkIWsdQ7iQjO-vQMMF5-qrhcm9fy5s_62TO6QMQVDmdevIafFwbEC-9Rahx9BHJckbc8WgwZlF3Kn2T7L5av9YV8yvBch6e9orQtdkUVSGS87yAJLXnOALyW=w1299-h866-no?authuser=0"
              }
              title={"Picnic"}
              description={
                "A picnic was held at Swaim Park to encourage inclusiveness and allow Asian youth in the community to get to know each other and have fun."
              }
            />
            <RecentEventCard
              type={"mobile"}
              image={workshop3}
              title={"Workshop"}
              description={
                "The youth workshop covered relevant topics like the Asian stereotype, mental health, careers, resume writing, and interview skills, with special guest speaker Aftab Pureval, mayor of Cincinnati."
              }
            />
          </Grid>
        </Box>
      );
    }
  }
}

export default RecentEvents;
