import React from "react";
import "./UpcomingEvents.css";

// Elements
import { Box, Typography } from "@mui/material"
import UpcomingEventCard from "./UpcomingEventCard";

// Images
import workshop4 from "../resources/workshop4.jpg";
import YouthLeadershipWorkshop from "../resources/YouthLeadershipWorkshop.png"

class UpcomingEvents extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Box sx={{ backgroundColor: "grey.100" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Upcoming Events
          </Typography>
          <Box sx={{ p: 10, pt: 5 }}>
            <UpcomingEventCard
              imageWidth={300}
              imageHeight={200}
              type={"normal"}
              image={workshop4}
              title={"Community Service Event"}
              description={`Date TBD, Location TBD`}
            />
          </Box>
        </Box>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Box sx={{ backgroundColor: "grey.100" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Upcoming Events
          </Typography>
          <Box sx={{ p: 5 }}>
            <UpcomingEventCard
              imageWidth={300}
              imageHeight={200}
              type={"mobile"}
              image={workshop4}
              title={"Community Service Event"}
              description={`Date TBD, Location TBD`}
            />
          </Box>
        </Box>
      );
    }
  }
}

export default UpcomingEvents;
