import React from "react";
import "./Home.css";

// Photo Imports
import workshop6 from "../resources/workshop6.jpg";

//Element Imports
import Headline from "../components/Headline";
import Mission from "../components/Mission";
import RecentEvents from "../components/RecentEvents";
import UpcomingEvents from "../components/UpcomingEvents";
import MeetOurTeam from "../components/MeetOurTeam";
import InfoBox from "../components/InfoBox";
import Footer from "../components/Footer";

class Home extends React.Component {
  render() {
    let width = window.innerWidth;
    if (width > 800) {
      return (
        <div className="">
          <Headline type={"normal"} image={workshop6} />

          <Mission type={"normal"} />

          {/* <Box sx={{ backgroundColor: 'grey.100', textAlign: "center" }}>
            <Typography variant="h4" sx={{ pt: 5}} gutterBottom>Aftab Pureval Guest Speaker (placeholder video)</Typography>
            <iframe className="featuredVideo" src="https://www.youtube.com/embed/bUGyonHPkBo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Box> */}

          <RecentEvents type={"normal"} />

          <UpcomingEvents type={"normal"} />

          <MeetOurTeam type={"normal"} />

          <InfoBox
            type={"normal"}
            title={"Interested? 👀"}
            description={
              "Whether you'd like to attend or help out at future community events, start by staying connected and getting updates."
            }
            link={"http://eepurl.com/hBkBQv"}
            linkName={"Sign Up"}
            image={
              "https://lh3.googleusercontent.com/pw/AM-JKLUcY_j2SljxQ0j8-UtRbMuh2jmVq7IdXgr8GbbXxLzVIOphr_XDKZKr-5cIzQbL3NUKkk_f4UavmvyJlPZy2uq93df9hqbNp9xBtYU0dYaN8z0xt_eSNRVNuARr-tVmFf8lx54J-b5RtHfYU1AoS3xT=w1299-h866-no?authuser=0"
            }
          />

          <Footer type={"normal"} />
        </div>
      );
    } else {
      return (
        <div className="">
          <Headline type={"mobile"} image={workshop6} />

          <Mission type={"mobile"} />

          {/* <Box sx={{ backgroundColor: 'grey.100', textAlign: "center" }}>
            <Typography variant="h4" sx={{ pt: 5}} gutterBottom>Aftab Pureval Guest Speaker (placeholder video)</Typography>
            <iframe className="featuredVideo" src="https://www.youtube.com/embed/bUGyonHPkBo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Box> */}

          <RecentEvents type={"mobile"} />

          <UpcomingEvents type={"mobile"} />

          <MeetOurTeam type={"mobile"} />

          <InfoBox
            type={"mobile"}
            title={"Interested? 👀"}
            description={
              "Whether you'd like to attend or help out at future community events, start by staying connected and getting updates."
            }
            link={"http://eepurl.com/hBkBQv"}
            linkName={"Sign Up"}
            image={
              "https://lh3.googleusercontent.com/pw/AM-JKLUcY_j2SljxQ0j8-UtRbMuh2jmVq7IdXgr8GbbXxLzVIOphr_XDKZKr-5cIzQbL3NUKkk_f4UavmvyJlPZy2uq93df9hqbNp9xBtYU0dYaN8z0xt_eSNRVNuARr-tVmFf8lx54J-b5RtHfYU1AoS3xT=w1299-h866-no?authuser=0"
            }
          />

          <Footer type={"mobile"} />
        </div>
      );
    }
  }
}

export default Home;
