import React from "react";
import "./InfoBox.css";

import { Box, Grid, Typography, Paper } from "@mui/material";

class InfoBox extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Box>
          <Grid container sx={{ p: 5 }}>
            <Grid
              item
              xs={6}
              sx={{
                position: "relative",
                p: { xs: 10 },
              }}
            >
              <Typography variant="h4" sx={{ pt: 5 }}>
                {this.props.title}
              </Typography>

              <Typography paragraph>{this.props.description}</Typography>
              {this.props.link ? (
                <a href={this.props.link} target="blank">
                  {this.props.linkName}
                </a>
              ) : null}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                position: "relative",
                p: { xs: 5 },
              }}
            >
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 0,
                  backgroundColor: "grey.800",
                  color: "#fff",
                  mb: 0,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  minHeight: 300,
                  opacity: 0.85,
                  backgroundImage: `url("${this.props.image}")`,
                }}
              ></Paper>
            </Grid>
          </Grid>
        </Box>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Box>
          <Grid container sx={{ p: 5 }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "relative",
                p: { xs: 0 },
              }}
            >
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 0,
                  backgroundColor: "grey.800",
                  color: "#fff",
                  mb: 0,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  minHeight: 150,
                  opacity: 0.85,
                  backgroundImage: `url("${this.props.image}")`,
                }}
              ></Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                position: "relative",
                p: { xs: 2 },
              }}
            >
              <Typography variant="h4" sx={{ pt: 5 }}>
                {this.props.title}
              </Typography>

              <Typography paragraph>{this.props.description}</Typography>
              {this.props.link ? (
                <a href={this.props.link} target="blank">
                  {this.props.linkName}
                </a>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      );
    }
  }
}

export default InfoBox;
