import React from "react";
import "./Headline.css";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

class Headline extends React.Component {


  render() {
    if (this.props.type === "normal") {
      return (
        <Paper
          sx={{
            position: "relative",
            borderRadius: 0,
            backgroundColor: "grey.800",
            color: "#fff",
            opacity: 0.9,
            mb: 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minHeight: "100vh",
            backgroundImage: `url("${this.props.image}")`,
          }}
        >
          {/* Increase the priority of the background image */}
          {
            <img
              style={{ display: "none" }}
              src={this.props.image}
              alt="background"
            />
          }
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,.3)",
              minHeight: "100vh",
            }}
          />
          <Grid container sx={{ minHeight: "100vh" }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                width: "100%",
                pt: 8,
                pl: 5,
                opacity: 1,
                filter: "brightness(1.75)",
              }}
            >
              WE ARE THE <span className="aya">ASIAN YOUTH ALLIANCE</span>
            </Typography>
            <Grid xs={6} item sx={{ minHeight: "100vh" }}>
              <Box
                sx={{
                  position: "relative",
                  pl: 5,
                  pr: 5,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center"
                }}
              >
                <Typography variant="h5" color="inherit" paragraph>
                  The Asian Youth Alliance (AYA) is a student-led organization
                  based in Cincinnati, Ohio. We aim to serve the Asian youth,
                  planting SEEDs within the community to help them thrive in
                  life.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Paper>
      );
    } else if (this.props.type === "mobile") {
      return (
        <React.Fragment>
          <Paper
            sx={{
              position: "relative",
              borderRadius: 0,
              backgroundColor: "grey.800",
              color: "#fff",
              mb: 0,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "50vh",
              backgroundImage: `url("${this.props.image}")`,
            }}
          >
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: "none" }}
                src={this.props.image}
                alt="background"
              />
            }
          </Paper>

          <Box>
            <Grid container>
              <Grid item sx={{ p: 3 }}>
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h3" color="inherit" gutterBottom>
                    WE ARE THE <span className="aya">ASIAN YOUTH ALLIANCE</span>
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                    The Asian Youth Alliance (AYA) is a student-led organization
                    based in Cincinnati, Ohio. We aim to serve the Asian youth,
                    planting SEEDs within the community to help them thrive in
                    life.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      );
    }
  }
}

export default Headline;
