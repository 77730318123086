import React from "react";
import "./About.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import MeetOurTeam from "../components/MeetOurTeam";

class Home extends React.Component {
  render() {
    let width = window.innerWidth;
    if (width > 800) {
      return (
        <Box sx={{ mt: "40px", backgroundColor: "grey.200" }}>
          {/* <Typography variant="h4" sx={{ pt: 10, textAlign: "center" }}>
            Our Story
          </Typography> */}
          <MeetOurTeam type="normal"/>
        </Box>
      );
    } else {
      return (
        <Box sx={{ backgroundColor: "grey.200" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Meet Our Team
          </Typography>
          <MeetOurTeam type={"mobile"}/>
        </Box>
      );
    }
  }
}

export default Home;
