import React from "react";
import "./MeetOurTeam.css";

// Elements
import { Box, Grid, Typography } from "@mui/material"
import TeamProfile from "./TeamProfile";

// Photos
import kyle from "../resources/kyle.jpeg";
import jaden from "../resources/jaden.jpeg";
import johan from "../resources/johan.jpg";
import victoria from "../resources/victoria.jpg";
import rishika from "../resources/rishika.png";
import jeremy from "../resources/jeremy.jpg";
import niki from "../resources/niki.jpeg";
import jesslyn from "../resources/jesslyn.jpg";
import daniella from "../resources/daniella.JPG";
import juliane from "../resources/juliane.jpg";

class MeetOurTeam extends React.Component {
  render() {
    if (this.props.type === "normal") {
      return (
        <Box sx={{ backgroundColor: "grey.200" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Meet Our Team
          </Typography>
          <Grid container sx={{ p: 5 }}>
            <TeamProfile
              type={"normal"}
              image={jeremy}
              name={"Lance Jeremiah Lim"}
              school={"Sycamore High School"}
              position={"Founder and Chairman of the Board"}
              year={"2020-present"}
            />
            <TeamProfile
              type={"normal"}
              image={daniella}
              name={"Daniella Endaya"}
              school={"Ursuline Academy"}
              position={"Core Leadership Team, Logistics Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"normal"}
              image={jesslyn}
              name={"Jesslyn Gunadi"}
              school={"Indian Hill High School"}
              position={"Core Leadership Team, Program Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"normal"}
              image={victoria}
              name={"Victoria Holes"}
              school={"Mason Middle School"}
              position={"Core Leadership Team, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"normal"}
              image={rishika}
              name={"Rishika Jeyaprakash"}
              school={"William Mason High School"}
              position={"Core Team Leader, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"normal"}
              image={jaden}
              name={"Jaden Lim"}
              school={"Ursuline Academy"}
              position={"Core Team Leader, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"normal"}
              image={niki}
              name={"Niki Rajaram"}
              school={"William Mason High School"}
              position={"Core Team Leader, Program Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"normal"}
              image={johan}
              name={"Johan Trajano"}
              school={"Saint Xavier High School"}
              position={"Core Team Leader, Media and Publicity Director"}
              year={"2022-present"}
            />
          </Grid>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Alumni
          </Typography>
          <Grid container sx={{ p: 5 }}>
            <TeamProfile
              type={"normal"}
              image={juliane}
              name={"Juliane Lim"}
              school={"Indiana University"}
            />
            <TeamProfile
              type={"normal"}
              image={kyle}
              name={"Kyle Macasilli-Tan"}
              school={"University of Southern California"}
            />
          </Grid>
        </Box>
      );
    } else if (this.props.type === "mobile") {
      return (
        <Box sx={{ backgroundColor: "grey.200" }}>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Meet Our Team
          </Typography>
          <Grid container sx={{ p: 5 }}>
            <TeamProfile
              type={"mobile"}
              image={jeremy}
              name={"Jeremy Lim"}
              school={"Sycamore High School"}
              position={"Founder and Chairman of the Board"}
              year={"2020-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={daniella}
              name={"Daniella Endaya"}
              school={"Ursuline Academy"}
              position={"Core Leadership Team, Logistics Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={jesslyn}
              name={"Jesslyn Gunadi"}
              school={"Indian Hill High School"}
              position={"Core Leadership Team, Program Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={victoria}
              name={"Victoria Holes"}
              school={"Mason Middle School"}
              position={"Core Leadership Team, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={rishika}
              name={"Rishika Jeyaprakash"}
              school={"William Mason High School"}
              position={"Core Team Leader, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={jaden}
              name={"Jaden Lim"}
              school={"Ursuline Academy"}
              position={"Core Team Leader, Logistics Director"}
              year={"2021-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={niki}
              name={"Niki Rajaram"}
              school={"William Mason High School"}
              position={"Core Team Leader, Program Director"}
              year={"2022-present"}
            />
            <TeamProfile
              type={"mobile"}
              image={johan}
              name={"Johan Trajano"}
              school={"Saint Xavier High School"}
              position={"Core Team Leader, Media and Publicity Director"}
              year={"2022-present"}
            />

          </Grid>
          <Typography variant="h4" sx={{ pt: 5, textAlign: "center" }}>
            Alumni
          </Typography>
          <Grid container sx={{ p: 5 }}>
          <TeamProfile
              type={"mobile"}
              image={juliane}
              name={"Juliane Lim"}
              school={"Indiana University"}
            />
            <TeamProfile
              type={"mobile"}
              image={kyle}
              name={"Kyle Macasilli-Tan"}
              school={"University of Southern California"}
            />
          </Grid>
        </Box>
      );
    }
  }
}

export default MeetOurTeam;
