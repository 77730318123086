import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import { BrowserRouter } from "react-router-dom";
import logo from "./resources/logo.jpg";

import { Routes, Route, Link, Navigate } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ul>
        <li>
          <Link to="/">
            <img className="logo" alt="" src={logo} />
          </Link>
        </li>
        <li>
          <Link to="/" className="no-mobile">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="no-mobile">
            About
          </Link>
        </li>
        <li>
          <Link to="/gallery" className="no-mobile">
            Gallery
          </Link>
        </li>
        <li className="right no-mobile">
          <a href="http://eepurl.com/hBkBQv" target="blank">
            Sign Up
          </a>
        </li>
      </ul>
      

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
